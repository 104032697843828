import { viewportMatches } from "../util";

export default class ItemMagnet {

  constructor(item) {
    this.itemMagnet = document.querySelectorAll(item);
  }

  addMagnetEvent() {
    this.itemMagnet.forEach(el => el.addEventListener('mousemove', function (e) {
      const pos = this.getBoundingClientRect();
      const mx = e.clientX - pos.left - pos.width / 2;
      const my = e.clientY - pos.top - pos.height / 2;

      this.style.transform = 'translate(' + mx * 0.3 + 'px, ' + my * 0.6 + 'px)';
      this.style.transform += 'rotate3d(' + mx * -0.2 + ', ' + my * -0.6 + ', 0, 12deg)';

      if (this.children[1]) {
        this.children[1].style.transform = 'translate(' + mx * 0.025 + 'px, ' + my * 0.075 + 'px)';
      }
    }));

    this.itemMagnet.forEach(el => el.addEventListener('mouseleave', function () {
      this.style.transform = 'translate3d(0px, 0px, 0px)';
      this.style.transform += 'rotate3d(0, 0, 0, 0deg)';

      if (this.children[1]) {
        this.children[1].style.transform = 'translate3d(0px, 0px, 0px)';
      }
    }));
  }

  init() {
    if (this.itemMagnet && !viewportMatches(1200)) {
      this.addMagnetEvent();
    }
  }

}
