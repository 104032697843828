import { gsap, Power3 } from "gsap";

export const preload = () => {
  let i = 0;

  const counter = () => {
    if (i == 0) {
      i = 1;
      var elem = document.querySelector("[data-preloader-counter] span");
      var count = 1;
      var setCounter = setInterval(frame, 15);
      function frame() {
        if (count >= 99) {
          clearInterval(setCounter);
          i = 0;
        } else {
          count++;
          elem.innerHTML = count;
        }
      }
    }
  }

  const animationPreloaderClosing = () => {
    let tl = gsap.timeline();

    tl.to(".preloader-background-dark", {
      duration: 3,
      scaleX: 0,
      ease: Power3.easeOut,
      transformOrigin: "right"
    })
    tl.to(".preloader-counter span", {
      duration: .2,
      translateX: '-100%',
      ease: Power3.easeOut,
    }, 3)
    tl.to(".preloader-detail", {
      duration: 1,
      scaleX: 0,
      ease: Power3.easeOut,
      transformOrigin: "right"
    }, 3)
    tl.to(".preloader-background-light", {
      duration: 0.2,
      opacity: 0,
      ease: Power3.easeOut,
      transformOrigin: "right"
    }, 3)


    tl.to(".preloader", {
      duration: 0,
      scaleX: 0,
      transformOrigin: "right"
    }, 4)
  }

  sessionStorage.setItem('preloaderRan', false);

  const saveStatePreloader = () =>
    sessionStorage.setItem('preloaderRan', true);

  animationPreloaderClosing();
  counter();
  saveStatePreloader()
}
