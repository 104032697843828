import { gsap, Power3 } from "gsap";

export const transition = () => {

  const internLinks =
    Array.from(
      document.querySelectorAll('a[href^="http://127.0.0.1:5500/"]')
    );

  const animationTransition = (linkEl) => {
    let tl = gsap.timeline({ onComplete: navigate(linkEl) });

    tl.to('.transition', {
      duration: 1,
      scaleY: 1,
      transformOrigin: 'bottom',
      ease: Power3.easeOut,
    })
  }

  const navigate = (linkEl) => {
    setTimeout(() => {
      window.location = linkEl.href
    }, 1000)
  }

  // if (internLinks) {
  //   internLinks.forEach(
  //     link => link.addEventListener('click',
  //       (ev) => {
  //         ev.preventDefault();
  //         animationTransition(ev.target);
  //       }
  //     )
  //   );
  // }

}