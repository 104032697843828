import { gsap, Power3 } from "gsap";
import { preloaderDuration } from "../util";

export const hero = () => {
  const preloaderRan = sessionStorage.getItem('preloaderRan');

  const animationHero = () => {
    let tl = gsap.timeline();

    tl.to('.home-hero-unequal', {
      duration: 2,
      translateX: '0',
      opacity: 1,
      ease: Power3.easeOut,
    }, 0)

    tl.to('.home-hero h1', {
      duration: .8,
      translateX: 0,
      opacity: 1,
      ease: Power3.easeOut,
    }, 0)

    tl.to('.home-hero p', {
      duration: .4,
      translateX: 0,
      opacity: 1,
      stagger: .3,
      ease: Power3.easeOut,
    }, .5);
  }

  gsap.to(".home-hero-description", {
    yPercent: 30,
    scrollTrigger: {
      trigger: ".home-hero",
      scrub: .05
    },
  });

  gsap.to(".home-hero-unequal img", {
    yPercent: 100,
    scrollTrigger: {
      trigger: ".home-hero",
      scrub: true
    },
  });

  if (!preloaderRan) {
    animationHero()
  } else {
    setTimeout(function () {
      animationHero()
    }, preloaderDuration);
  }

}
