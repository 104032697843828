import { gsap, Power2 } from "gsap";

export const clients = () => {

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".home-clients",
      start: "top 50%",
      end: "bottom top",
      toggleActions: "play none none none",
      scrub: false,
      once: true
    }
  });

  tl.from(".home-clients-title-line", {
    scale: 0,
    ease: Power2.easeOut,
    duration: 1,
    transformOrigin: 'left'
  }, 0)
  tl.from(".home-clients-figure", {
    opacity: 0,
    ease: Power2.easeOut,
    duration: 1,
    stagger: .2
  }, .2)

}