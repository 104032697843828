import { gsap, Power2 } from "gsap";

export const contact = () => {

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".home-contact",
      start: "top 50%",
      end: "bottom top",
      toggleActions: "play none none none",
      scrub: false,
      once: true
    }
  });

  tl.from(".home-contact-title", {
    translateY: '30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, 0)
  tl.from(".home-contact-description", {
    translateY: '30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, .3)
  tl.from(".input-wrapper", {
    translateY: '30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .3,
    stagger: .2
  }, .3)
  tl.from(".home-contact .circle-button", {
    translateY: '30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .3,
    stagger: .2
  }, .3)
  tl.from(".home-contact-detail", {
    scaleX: 0,
    ease: Power2.easeOut,
    transformOrigin: 'left',
    duration: .3,
    stagger: .2
  }, .3)

  gsap.to(".home-contact-title", {
    yPercent: 20,
    scrollTrigger: {
      trigger: ".home-contact",
      scrub: true
    },
  });

  gsap.to(".home-contact-description", {
    yPercent: -60,
    scrollTrigger: {
      trigger: ".home-contact",
      scrub: true
    },
  });

}