import CircleButton from "../animations/CircleButton";
import { clients } from "../animations/clients";
import { contact } from "../animations/contact";
import ItemMagnet from "../animations/ItemMagnet";
import { news } from "../animations/news";
import { newsDrag } from "../animations/newsDrag";
import { cursor } from "../modules/cursor";
import { defer } from "../modules/defer";
import { initTypewriter } from "../modules/typewriter";

import { baseUrl } from "../util"

export const initFetchNews = () => {
  async function fetchNews(url) {
    const newsResponse = await fetch(url);
    const newsJSON = await newsResponse.json();

    createFeatured(newsJSON[0])

    newsJSON.slice(1).forEach((post) => {
      const list = createBlogPosts(post);
      addBlogPosts(list);
    })
  }

  const createFeatured = (item) => {
    const featuredElement = document.querySelector('[data-news-featured]');
    const featuredWrapper = document.createElement('div');
    featuredWrapper.innerHTML = `
      <div class="news-hero-overlay"></div>
      <figure>
        <img
          data-src="${item.image}"
          alt="${item.title}"
          class="img-fluid defer">
      </figure>
      <div class="container-fluid">
        <a href="./single.html?slug=${item.slug}" title="${item.title}">
          <p class="news-hero-date">${item.date}</p>
          <h1 class="news-hero-title" data-hoverable>${item.title}</h1>
          <div class="news-hero-excerpt">
            <p>
              ${item.excerpt}
            </p>
          </div>
        </a>
      </div>
    `;

    featuredElement.appendChild(featuredWrapper);
  }


  const createBlogPosts = (post) => {
    const postItem = document.createElement('li');
    postItem.classList.add('news-item')

    postItem.innerHTML = `
      <article class="news-thumb news-thumb-dark">
        <a href="./single.html?slug=${post.slug}" title="${post.title}">
          <figure class="news-thumb-figure">
            <img
              data-src="${post.thumbnail}"
              data-scaleImg-image
              class="img-fluid defer news-thumb-img"
              alt="${post.title}">
          </figure>

          <p class="news-thumb-date">${post.date}</p>
          <h1 class="news-thumb-title">${post.title}</h1>
          <div class="news-thumb-excerpt">
            <p>
              ${post.excerpt}...
            </p>
          </div>
          <button data-hoverable class="news-thumb-button">
            <span>
              Ler Mais
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.284" height="28.284"><defs><clipPath id="a"><path data-name="Retângulo 11" transform="translate(0 -.12)" fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs><g data-name="Grupo 2"><g data-name="Grupo 1" transform="rotate(-45 17.21596907 7.01191212)" clip-path="url(#a)"><path data-name="Caminho 1" d="M9.61.27a9.61 9.61 0 1 0 6.8 2.815A9.609 9.609 0 0 0 9.61.27Zm4.724 10.453-3.642 3.642a1.14480588 1.14480588 0 0 1-1.619-1.619l1.75-1.734h-5.13a1.141 1.141 0 1 1 0-2.282H10.8L9.077 7.007A1.14622096 1.14622096 0 1 1 10.7 5.388l3.637 3.642a1.133 1.133 0 0 1 .33.85v.036a1.137 1.137 0 0 1-.334.807Z" fill="#fff"/></g></g></svg>
          </button>
        </a>
      </article>
    `;

    return postItem;
  }

  const addBlogPosts = (list) => {
    const blogList = document.querySelector('[data-news-list]');

    if (blogList)
      blogList.appendChild(list)
  }

  fetchNews(baseUrl + 'posts').then(() => {
    defer();
    cursor();
  })
}