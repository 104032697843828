import { gsap, Power2 } from "gsap";

export const footer = () => {

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".footer-top",
      start: "top 50%",
      end: "bottom top",
      toggleActions: "play none none none",
      scrub: false,
      once: true
    }
  });

  tl.from(".footer-top-line", {
    scale: 0,
    ease: Power2.easeOut,
    duration: 1,
    transformOrigin: 'left'
  }, 0)
  tl.from(".footer-logo", {
    translateY: '-30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, 0)
  tl.from(".footer-contacts", {
    translateY: '-30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, .2)
  tl.from(".footer-address", {
    translateY: '-30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, .4)
  tl.from(".footer-social", {
    translateY: '-30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, .6)
  tl.from(".footer-backtop", {
    translateY: '-30px',
    opacity: 0,
    ease: Power2.easeOut,
    duration: .5,
  }, .8)

}