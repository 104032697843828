import { baseUrl } from "../util"
import { defer } from "../modules/defer"

export const initFetchPage = () => {
  async function fetchPage(url) {
    const pageResponse = await fetch(url);
    const pageJSON = await pageResponse.json();

    const pageContent = document.querySelector('[data-page-content]');
    const pageTitle = document.querySelector('[data-page-title]');

    pageContent.innerHTML = pageJSON[0].content;
    pageTitle.innerHTML = pageJSON[0].title;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');

  fetchPage(baseUrl + 'page/' + slug).then(() => {
    defer()
  })
}