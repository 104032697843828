import { cases } from "../animations/cases";
import CircleButton from "../animations/CircleButton";
import ItemMagnet from "../animations/ItemMagnet";
import CasePopup from "../modules/Case";
import { baseUrl } from "../util"

export const initFetchCases = () => {
  async function fetchCases(url) {
    const casesResponse = await fetch(url);
    const casesJSON = await casesResponse.json();
    const casesList = document.querySelector('[data-cases-list]');

    casesJSON.forEach(cases => {
      const list = createCases(cases)
      if (casesList)
        casesList.appendChild(list)
    })
  }

  function createCases(cases) {
    const itemList = document.createElement('li')
    itemList.classList.add('home-cases-item');

    itemList.innerHTML = `
      <article
        class="case-thumb"
        style="background-color: ${cases.color}"
        >
        <h1 class="case-thumb-title">${cases.title}</h1>
        <p class="case-thumb-description">${cases.description}</p>
        <button
          data-hoverable
          data-item-magnet
          data-circle-button
          data-case-button
          data-case-url="${cases.id}"
          class="circle-button case-thumb-button">
          <div class="circle-button-deco">
            <div
              class="circle-button-deco-filler"
              data-circle-button-filler></div>
          </div>
          <div class="circle-button-text">
            <div class="circle-button-text-inner">
              Ver Projeto
            </div>
          </div>
        </button>
        <img
          src="${cases.image}"
          alt="${cases.title}"
          data-scaleImg-image
          class="img-fluid case-thumb-image">
      </article>
    `;

    return itemList;
  }

  fetchCases(baseUrl + 'projects').then(() => {
    cases()
    const circleButton = new CircleButton('.case-thumb [data-circle-button]');
    circleButton.init();

    const itemMagnet = new ItemMagnet('.case-thumb [data-item-magnet]');
    itemMagnet.init();

    const casePopup = new CasePopup('[data-case-button]');
    casePopup.init();

    const caseDrag = () => {
      const slider = document.querySelector('[data-cases-list]');
      let isDown = false;
      let startX;
      let scrollLeft;

      if (slider) {
        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          slider.classList.add('active');
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 2;
          slider.scrollLeft = scrollLeft - walk;
        });
      }
    }

    caseDrag()

  })
}