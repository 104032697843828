import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import transition from '../../components/global/transition.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/home/hero.html';
import cases from '../../components/home/cases.html';
import clients from '../../components/home/clients.html';
import news from '../../components/home/news.html';
import contact from '../../components/home/contact.html';

import footer from '../../components/layout/footer.html';

export const homeRoot = document.getElementById("homeRoot");
const homeHead = document.getElementById("homeHead");
const homeContent = document.getElementById("homeContent");
const homeFoot = document.getElementById("homeFoot");

export const initHomePage = () => {
  if (homeRoot && homeHead && homeContent && homeFoot) {
    homeRoot.innerHTML =
      cursor +
      preloader +
      transition +
      cookies;

    homeHead.innerHTML =
      header;

    homeContent.innerHTML =
      hero +
      cases +
      clients +
      news +
      contact;

    homeFoot.innerHTML =
      footer;
  }
}