import { gsap, Power3 } from "gsap";
import { preloaderDuration } from "../util";

export const header = () => {
  const preloaderRan = sessionStorage.getItem('preloaderRan');

  const animationHeaderOpening = () => {
    let tl = gsap.timeline();
    let isMobile =
      window
        .matchMedia("(max-width: 1300px)")
        .matches;

    tl.to('.header-logo', {
      duration: 1,
      translateY: 0,
      opacity: 1,
      ease: Power3.easeOut,
    }, 0)

    if (isMobile) {
      tl.to('.header-hamburger', {
        duration: 1,
        translateY: 0,
        opacity: 1,
        ease: Power3.easeOut,
      }, .3)
    } else {
      tl.to('.header-menu .menu li', {
        duration: 1,
        translateY: 0,
        opacity: 1,
        stagger: .2,
        ease: Power3.easeOut,
      }, .3);
    }
  }

  let animationHeaderScroll = gsap.timeline({ paused: true });

  animationHeaderScroll.to('.header', {
    translateY: '-190px',
    duration: .4,
    ease: Power3.easeOut,
  }, 0);

  let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const handleHeaderScrollAnimation = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      animationHeaderScroll.play()
    } else {
      animationHeaderScroll.reverse()
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

  if (!preloaderRan) {
    animationHeaderOpening()
  } else {
    setTimeout(function () {
      animationHeaderOpening()
    }, preloaderDuration);
  }

  window.addEventListener('scroll', handleHeaderScrollAnimation)
}
