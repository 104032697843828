import { baseUrl } from '../util'
import Scrollbar from 'smooth-scrollbar'

export default class CasePopup {
  constructor(buttons, popupClose) {
    this.button = document.querySelectorAll(buttons)
    this.popupClose = document.querySelectorAll(popupClose)
  }

  renderImages(images) {
    let imagesList;

    console.log('before foreach', imagesList)

    images.forEach(image => {
      let imageElement = '<img src="' + image + '" class="img-fluid">'
      console.log('in foreach', imagesList)
      imagesList = imagesList + imageElement;
    })

    console.log('after foreach', imagesList)

    return imagesList;
  }

  openCasePopup() {
    this.button.forEach(button => {
      button.addEventListener('click', async () => {
        const productSlug = button.dataset.caseUrl;

        const caseData = await this.fetchCase(baseUrl + 'project/' + productSlug)

        const popup = document.createElement('div');
        popup.classList.add('case-popup')
        popup.innerHTML = `
          <div 
            class="case-popup-overlay"
            data-case-popup-close></div>
          <article class="case-popup-content" data-popup-scrollbar>
            <div
              class="case-popup-close"
              data-case-popup-close>x</div>
      
            <div class="case-popup-title">
              <h1>${caseData.title}</h1>
              <h2>${caseData.short_description}</h2>
            </div>

            <div class="case-popup-description">
              ${caseData.description ? `
                <div class="case-popup-description-content content">
                  <h2>${caseData.description_title}</h2>
                  ${caseData.description}
                </div>
              ` : ''}
              ${caseData.description_image ? `
                <div class="case-popup-description-image">
                  <img class="img-fluid" src="${caseData.description_image}">
                </div>
              ` : ''}
            </div>
      
            ${caseData.challenges ?
            `<div class="case-popup-row">
                <div class="case-popup-column content">
                  ${caseData.challenges[0].content}
                </div>
                <div class="case-popup-column content">
                  ${caseData.challenges[1].content}
                </div>
                <div class="case-popup-column content">
                  ${caseData.challenges[2].content}
                </div>
              </div>
              ` : ''}
              
            ${caseData.challenges_images ?
            `<div class="case-popup-images">${this.renderImages(caseData.challenges_images)}</div>
                ` : ''}

            

            ${caseData.description_images ?
            `<div class="case-popup-images">${this.renderImages(caseData.description_images)}</div>
                ` : ''}

            ${caseData.results ?
            `
            <div class="case-popup-results">
              <h2>Resultados</h2>
            </div>
            <div class="case-popup-row">
              <div class="case-popup-column content">
                ${caseData.results[0].content}
              </div>
              <div class="case-popup-column content">
                ${caseData.results[1].content}
              </div>
              <div class="case-popup-column content">
                ${caseData.results[2].content}
              </div>
            </div>
            ` : ''}
            
          </article>
        `;

        document.body.appendChild(popup)

        const casePopupClose = Array.from(
          document.querySelectorAll('[data-case-popup-close]')
        );

        const closeCasePopup = () => popup.remove()

        casePopupClose.forEach(popupClose => {
          popupClose.addEventListener('click', closeCasePopup)
        })

        const scroller = document.querySelector('[data-popup-scrollbar]')

        const popupScrollBar = Scrollbar.init(scroller, {
          enable: true,
          damping: 0.2,
          maxOverscroll: 150,
        });
      })
    })
  }

  async fetchCase(url) {
    const caseResponse = await fetch(url);
    return await caseResponse.json();
  }

  init() {
    if (this.button) {
      this.openCasePopup()
    }
  }
}