import { gsap } from 'gsap';

export const news = () => {
  const scrollingTitle = () => {
    document.body.style.overflow = 'auto';
    document.scrollingElement.scrollTo(0, 0);

    const isMobile = window.matchMedia("(max-width: 1024px)").matches;

    gsap.utils.toArray('.home-news').forEach((section, index) => {
      const w = section.querySelector('.home-news-title');
      const [x, xEnd] = ['-50%', (w.scrollWidth - section.offsetWidth) * -1];
      gsap.fromTo(w, { x }, {
        x: xEnd,
        scrollTrigger: {
          trigger: section,
          scrub: (isMobile) ? .5 : .075,
        }
      });
    });
  }

  const thumbAnimation = () => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".news-thumb",
        start: "top bottom",
        end: "bottom top",
        toggleActions: "play none none none"
      }
    });

    tl.from(".news-thumb-img", {
      scale: 1.2,
      ease: "power1.out",
      duration: 1,
      stagger: .2
    })
  }

  scrollingTitle()
  thumbAnimation()
}