import "../sass/style.scss";

import { homeRoot, initHomePage } from "./pages/home";
import { initAboutPage } from "./pages/about";
import { initNewsPage, newsRoot } from "./pages/news";
import { initSinglePage, singleRoot } from "./pages/single";

import { cursor } from "./modules/cursor";
import "./modules/smoothScroll";
import "./modules/Case";

import { preload } from "./animations/preload";
import { header } from "./animations/header";
import { menu } from "./animations/menu";
import { footer } from "./animations/footer";
import { transition } from "./modules/transition";
import { initFetchCases } from "./services/cases";
import { defer } from "./modules/defer";
import CircleButton from "./animations/CircleButton";
import ItemMagnet from "./animations/ItemMagnet";
import AnchorNav, { scrollAfterNavigate } from "./modules/anchorNav";
import { initFetchHome } from "./services/home";
import { initFetchGeneral } from "./services/general";
import { initFetchNews } from "./services/news";
import { initFetchSingle } from "./services/single";
import { initFetchPage } from "./services/page";
import { initPage, pageRoot } from "./pages/page";
import { MailSender } from "./modules/MailSender";

window.addEventListener("load", () => {
  

  if (homeRoot) {
    initHomePage();
    initFetchHome();
    initFetchCases();
  }

  const contactForm = document.querySelector('[data-contact-form]');

  if(contactForm) {
    const contactFormSend = new MailSender(
      contactForm,
      {
        'name': document.querySelector('input[name=name]'),
        'phone': document.querySelector('input[name=phone]'),
        'email': document.querySelector('input[name=email]'),
        'subject': document.querySelector('input[name=subject]'),
        'message': document.querySelector('input[name=message]')
      },
      './sendContact.php',
      '[data-contact-form-response]'
    );
  
    contactForm.addEventListener('submit', (e) => {
      contactFormSend.submit(e);
    })
  }

  initAboutPage();

  if (newsRoot) {
    initNewsPage();
    initFetchNews();
  }


  if (singleRoot) {
    initSinglePage();
    initFetchSingle();
  }

  if (pageRoot) {
    initPage();
    initFetchPage();
  }

  initFetchGeneral();

  defer();
  preload();
  transition();
  cursor();

  const circleButton = new CircleButton('[data-circle-button]');
  circleButton.init();

  const itemMagnet = new ItemMagnet('[data-item-magnet]');
  itemMagnet.init();

  const anchorLinks = new AnchorNav('[data-anchor]');
  anchorLinks.init();

  header();
  menu();
  footer();

  scrollAfterNavigate()
});
