import CircleButton from "../animations/CircleButton";
import { clients } from "../animations/clients";
import { contact } from "../animations/contact";
import { hero } from "../animations/hero";
import ItemMagnet from "../animations/ItemMagnet";
import { news } from "../animations/news";
import { newsDrag } from "../animations/newsDrag";
import { cursor } from "../modules/cursor";
import { defer } from "../modules/defer";
import { initTypewriter } from "../modules/typewriter";

import { baseUrl } from "../util"

export const initFetchHome = () => {
  async function fetchHome(url) {
    const homeResponse = await fetch(url);
    const homeJSON = await homeResponse.json();

    const hero = {
      title: homeJSON[0].hero_title,
      words: homeJSON[0].hero_words_slide,
      description: homeJSON[0].hero_description
    }

    const clients = {
      list: homeJSON[0].clients,
      title: homeJSON[0].clients_title
    }

    const contact = {
      description: homeJSON[0].contact_description,
      title: homeJSON[0].contact_title
    }

    createHero(hero)
    createClients(clients)
    createContact(contact)

    homeJSON[0].blog_posts.forEach(post => {
      const list = createBlogPosts(post)
      addPosts(list)
    })
  }

  const createHero = (hero) => {
    const title = document.querySelector('[data-home-hero-title');
    const wordsWrapper = document.createElement('u');

    const wordsArray = []

    hero.words.forEach(item => {
      wordsArray.push('"' + item.word + '"')
    });

    wordsWrapper.classList.add('typewriter')
    wordsWrapper.dataset.words = '[' + wordsArray + ']';
    wordsWrapper.dataset.wait = '3000';


    title.innerHTML = hero.title + ' ';
    title.appendChild(wordsWrapper);
  }

  const createClients = (clients) => {
    const title = document.querySelector('[data-home-clients-title]')
    const clientsWrapperList = document.querySelector('[data-home-clients-list]')

    title.innerHTML = clients.title;

    if (clientsWrapperList) {
      clients.list.forEach(client => {
        const listClients = createListClients(client);
        clientsWrapperList.appendChild(listClients)
      })
    }
  }

  const createListClients = (client) => {
    const clientItem = document.createElement('li');
    clientItem.classList.add('home-clients-item');

    const clientFigure = document.createElement('figure');
    clientFigure.classList.add('home-clients-figure');
    clientItem.appendChild(clientFigure)

    const clientImage = document.createElement('img');
    clientImage.classList.add('img-fluid')
    clientImage.classList.add('defer')
    clientImage.dataset.hoverable = '';
    clientImage.dataset.src = client.logo;

    if (client.link) {
      const clientLink = document.createElement('a');

      clientLink.href = client.link;
      clientLink.append(clientImage)
      clientFigure.append(clientLink)
    } else {
      clientFigure.append(clientImage)
    }

    return clientItem;
  }

  const createContact = (contact) => {
    const title = document.querySelector('[data-home-contact-title]')
    const description = document.querySelector('[data-home-contact-description]')

    title.innerHTML = contact.title;
    description.innerHTML = contact.description;
  }

  const createBlogPosts = (post) => {
    const postItem = document.createElement('li');
    postItem.classList.add('home-news-item')

    postItem.innerHTML = `
      <article class="news-thumb news-thumb-light">
        <a href="./single.html?slug=${post.slug}" title="${post.title}">
          <figure class="news-thumb-figure">
            <img
              data-src="${post.image}"
              data-scaleImg-image
              class="img-fluid defer news-thumb-img"
              alt="${post.title}">
          </figure>

          <p class="news-thumb-date">${post.date}</p>
          <h1 class="news-thumb-title">${post.title}</h1>
          <div class="news-thumb-excerpt">
            <p>
              ${post.excerpt}
            </p>
          </div>
          <button data-hoverable class="news-thumb-button">
            <span>
              Ler Mais
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.284" height="28.284"><defs><clipPath id="a"><path data-name="Retângulo 11" transform="translate(0 -.12)" fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs><g data-name="Grupo 2"><g data-name="Grupo 1" transform="rotate(-45 17.21596907 7.01191212)" clip-path="url(#a)"><path data-name="Caminho 1" d="M9.61.27a9.61 9.61 0 1 0 6.8 2.815A9.609 9.609 0 0 0 9.61.27Zm4.724 10.453-3.642 3.642a1.14480588 1.14480588 0 0 1-1.619-1.619l1.75-1.734h-5.13a1.141 1.141 0 1 1 0-2.282H10.8L9.077 7.007A1.14622096 1.14622096 0 1 1 10.7 5.388l3.637 3.642a1.133 1.133 0 0 1 .33.85v.036a1.137 1.137 0 0 1-.334.807Z" fill="#fff"/></g></g></svg>
          </button>
        </a>
      </article>
    `;

    return postItem;
  }

  const addPosts = (list) => {
    const blogList = document.querySelector('[data-home-news-list]');

    if (blogList)
      blogList.appendChild(list)
  }

  fetchHome(baseUrl + 'home').then(() => {
    hero()
    initTypewriter();
    clients()
    contact()
    news();
    newsDrag();
    defer();
    cursor();
  })
}