import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { bodyScrollBar } from './smoothScroll';
import { homeUrl, preloaderDuration } from '../util';

gsap.registerPlugin(ScrollToPlugin);

export default class AnchorNav {
  constructor(links) {
    this.links = document.querySelectorAll(links)
  }

  navigateTo() {
    this.links.forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault()

        const targetID = link.href.split('#').pop();
        const target = document.getElementById(targetID);

        if (target) {
          bodyScrollBar.scrollIntoView(target)
          return;
        }

        window.location.href = homeUrl + "?nav=" + targetID;
      });
    })
  }

  init() {
    if (this.links) {
      this.navigateTo()
    }
  }
}

export const scrollAfterNavigate = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has('nav')) {
    const nav = urlParams.get('nav')

    const target = document.getElementById(nav);

    if (target) {
      setTimeout(function () {
        bodyScrollBar.scrollIntoView(target)
      }, preloaderDuration);
    }
  }
}