import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import transition from '../../components/global/transition.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import content from '../../components/page/content.html';

import footer from '../../components/layout/footer.html';

export const pageRoot = document.getElementById("pageRoot");
const pageHead = document.getElementById("pageHead");
const pageContent = document.getElementById("pageContent");
const pageFoot = document.getElementById("pageFoot");

export const initPage = () => {
  if (pageRoot && pageHead && pageContent && pageFoot) {
    pageRoot.innerHTML =
      cursor +
      preloader +
      transition +
      cookies;

    pageHead.innerHTML =
      header;

    pageContent.innerHTML =
      content;

    pageFoot.innerHTML =
      footer;
  }
}