import { gsap, Power2 } from "gsap";

export const cases = () => {

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".home-cases",
      start: "top 50%",
      end: "bottom top",
      toggleActions: "play none none none",
      scrub: false,
      once: true
    }
  });

  tl.from(".case-thumb", {
    opacity: 0,
    translateX: '120px',
    ease: Power2.easeOut,
    duration: 1,
  }, 0)
  tl.from(".case-thumb-image", {
    scale: 1.4,
    ease: Power2.easeOut,
    duration: 1,
  }, 0)
  tl.from(".case-thumb-title", {
    translateY: '30px',
    ease: Power2.easeOut,
    duration: 1,
  }, .3)
  tl.from(".case-thumb-description", {
    translateY: '30px',
    ease: Power2.easeOut,
    duration: 1,
    opacity: 0,
  }, .5)
  tl.from(".case-thumb-button", {
    translateY: '40px',
    ease: Power2.easeOut,
    duration: 1,
    opacity: 0,
  }, .7)
  tl.from(".home-cases-detail", {
    scaleX: 0,
    transformOrigin: 'left',
    ease: Power2.easeOut,
    duration: 1.4,
  }, 1)

}