import gsap from "gsap";
import { viewportMatches } from "../util";

export default class CircleButton {

  constructor(button) {
    this.circleButton = document.querySelectorAll(button);
  }

  addButtonEvent() {

    this.circleButton.forEach(button => {
      button.addEventListener('mouseenter', function (e) {
        let targetFiller =
          e.target.querySelector('[data-circle-button-filler]');

        gsap
          .timeline()
          .to(targetFiller, {
            ease: 'Power3.easeOut',
            startAt: { y: '75%' },
            y: '0%',
            duration: 0.4
          })
      });
    })
    this.circleButton.forEach(button => {
      button.addEventListener('mouseleave', function (e) {
        let targetFiller =
          e.target.querySelector('[data-circle-button-filler]');

        gsap
          .timeline()
          .to(targetFiller, {
            ease: 'Power3.easeOut',
            y: '-75%',
            duration: 0.3
          })
      });
    })
  }

  init() {
    if (this.circleButton && !viewportMatches(1200)) {
      this.addButtonEvent()
    }
  }
}