import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import transition from '../../components/global/transition.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/news/hero.html';
import list from '../../components/news/list.html';

import footer from '../../components/layout/footer.html';

export const newsRoot = document.getElementById("newsRoot");
const newsHead = document.getElementById("newsHead");
const newsContent = document.getElementById("newsContent");
const newsFoot = document.getElementById("newsFoot");

export const initNewsPage = () => {
  if (newsRoot && newsHead && newsContent && newsFoot) {
    newsRoot.innerHTML =
      cursor +
      preloader +
      transition +
      cookies;

    newsHead.innerHTML =
      header;

    newsContent.innerHTML =
      hero +
      list;

    newsFoot.innerHTML =
      footer;
  }
}