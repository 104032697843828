import { baseUrl } from "../util"
import { defer } from "../modules/defer"

export const initFetchSingle = () => {
  async function fetchSingle(url) {
    const singleResponse = await fetch(url);
    const singleJSON = await singleResponse.json();

    let hero = {
      title: singleJSON.title,
      image: singleJSON.image,
      date: singleJSON.date,
      excerpt: singleJSON.excerpt
    }

    addHero(hero)

    const singleContent = document.querySelector('[data-single-content]');

    singleContent.innerHTML = singleJSON.content;

    // singleJSON.related.forEach((item) => {
    //   const list = createRelated(item);
    //   addRelated(list);
    // })
  }

  const addHero = (hero) => {
    const image = document.querySelector('[data-single-hero="image"]');
    const title = document.querySelector('[data-single-hero="title"]');
    const date = document.querySelector('[data-single-hero="date"]');
    const excerpt = document.querySelector('[data-single-hero="excerpt"]');

    image.setAttribute('data-src', hero.image)
    image.setAttribute('alt', hero.title)

    title.innerHTML = hero.title
    date.innerHTML = hero.date
    excerpt.innerHTML = hero.excerpt
  }

  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');

  fetchSingle(baseUrl + 'post/' + slug).then(() => {
    defer()
  })
}