import CircleButton from "../animations/CircleButton";
import ItemMagnet from "../animations/ItemMagnet";
import { cursor } from "../modules/cursor";
import { defer } from "../modules/defer";
import { addCookies, checkCookiesIsAccepted } from "../modules/cookies";

import { baseUrl } from "../util"

export const initFetchGeneral = () => {
  async function fetchGeneral(url) {
    const generalResponse = await fetch(url);
    const generalJSON = await generalResponse.json();

    createPhone(generalJSON[0].phone)
    createEmail(generalJSON[0].email)
    createMapLink(generalJSON[0].map_link)
    createAddress(generalJSON[0].address, generalJSON[0].map_link)

    generalJSON[0].social.forEach(item => {
      const list = createSocialItems(item)
      addSocialItems(list)
    })

    addCookies(generalJSON[0].cookies_message)
  }

  const createPhone = (phone) => {
    const phoneElement = document.querySelector('[data-footer-phone]');
    const phoneLink = 'tel:' + phone.replace(/\D/g, "");

    phoneElement.setAttribute('href', phoneLink)
    phoneElement.innerHTML = phone;
  }

  const createEmail = (email) => {
    const emailElement = document.querySelector('[data-footer-email]');
    const link = 'mailto:' + email;

    emailElement.setAttribute('href', link)
    emailElement.innerHTML = email;
  }

  const createAddress = (address, link) => {
    const addressElement = document.querySelector('[data-footer-address]');

    addressElement.setAttribute('href', link)
    addressElement.innerHTML = address;
  }

  const createMapLink = (link) => {
    const mapElement = document.querySelector('[data-footer-map-link]');

    mapElement.setAttribute('href', link)
  }


  const createSocialItems = (item) => {
    const socialItem = document.createElement('li');
    socialItem.setAttribute('data-item-magnet', '')

    socialItem.innerHTML = `
      <a href="${item.link}" data-hoverable target="_blank" title="New Comunicação no ${item.title}">
        <img class="img-fluid defer" data-src="${item.icon}" alt="${item.title}"/>
      </a>
    `;

    return socialItem;
  }

  const addSocialItems = (list) => {
    const socialList = document.querySelector('[data-footer-social-list]');

    if (socialList)
      socialList.appendChild(list)
  }

  fetchGeneral(baseUrl + 'general').then(() => {
    defer();
    cursor();
    checkCookiesIsAccepted()

    const circleButton = new CircleButton('.footer-contact [data-circle-button]');
    circleButton.init();

    const itemMagnet = new ItemMagnet('.footer-contact [data-item-magnet]');
    itemMagnet.init();
  })
}